import React from "react"
import PropTypes from "prop-types"
import ExcluPage from "../containers/excluPage"
import doubloCAgif from "../images/doublo-caisse-americaine.gif"

const CadreEntreDeuxVerres = (props) => {
  return (
    <>
      <ExcluPage
        title="Cadre doublo caisse américaine"
        description={
          <>
            Ce cadre doublo caisse américaine permet de donner un effet très design et contemporain au sujet encadré (très apprécié des galeries d’art).
            <br />
            Spécialement conçu pour encadrer vos toiles sur châssis d’épaisseur de 2cm à 4cm.
            <br />
            La caisse américaine intérieure vous est proposée dans différentes couleurs.
          </>
        }
        link="/cadres/Cadre-doublo-caisse-americaine/"
        img={doubloCAgif}
        location={props.location}
      />
    </>
  )
}

CadreEntreDeuxVerres.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadreEntreDeuxVerres
